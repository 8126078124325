import { Box, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

interface IErrorCardProps {
  text?: string;
}

const defaultText =
  "O documento está expirado e não pode ser validado ou reemitido";

export const ErrorCard: React.FC<IErrorCardProps> = ({
  text = defaultText,
}) => {
  return (
    <Box
      sx={{
        padding: 2,
        marginTop: 2,
        backgroundColor: "#FDEDED",
        color: "#5F2120",
        display: "flex",
      }}
    >
      <Box sx={{ marginRight: 2 }}>
        <ErrorOutlineRoundedIcon />
      </Box>
      <Typography>{text || defaultText}</Typography>
    </Box>
  );
};
