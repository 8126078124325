import { createTheme } from "@mui/material";

export const LightEspelho = createTheme({
  palette: {
    primary: {
      main: '#666666',
      light: '#339F9A',
      dark: '#005E5A',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#888888 ',
      light: '#33C7BD',
      dark: '#008179',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F50000',
      light: '#F73333',
      dark: '#AB0000',
    },
    warning: {
      main: '#FFAC33',
      light: '#FF9800',
      dark: '#B26A00',
    },
    success: {
      main: '#33B673',
      light: '#00A451',
      dark: '#007238',
    },
    info: {
      main: '#018781',
      light: '#339F9A',
      dark: '#005E5A',
    },
    text: {
      primary: '#414141',
    },
    background: {
      default: '#F8F8F8',
      paper: '#FFFFFF',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
