import React, { useCallback } from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

import { logoGvDasa } from "shared/assets";
import { useDocsContext } from "shared/contexts/DocsContext";
import { formatarDataHora } from "shared/services/utils/formatarDataHora";
export const Result: React.FC = () => {
  const { chave, dataHoraGeracao, validatedDocument } = useDocsContext();

  const downloadFileFromBase64 = useCallback((base64Data, fileName) => {
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${base64Data}`;
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <Box style={{ width: "100%", height: "100%" }}>
      <AppBar position="static">
        <Toolbar>
          <img
            style={{ maxWidth: "100px", marginRight: "16px" }}
            alt="inadimplencia"
            src={logoGvDasa.default}
          />
          <Typography variant="h6">Autenticação de documentos</Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          paddingLeft: 24,
          paddingTop: 10,
        }}
      >
        <Box>
          <Box sx={{ paddingLeft: 22, marginBottom: 2 }}>
            <CheckCircleOutline color="primary" fontSize={"large"} />
          </Box>
          <Box>
            <Typography
              variant="h5"
              color={"primary"}
              sx={{ fontWeight: "bold" }}
            >
              Documento autenticado com sucesso!
            </Typography>
          </Box>
          <Box sx={{ marginTop: 5 }}>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Chave do documento: </span>
              {chave}
            </Typography>
            <Typography variant="body1">
              <span style={{ fontWeight: "bold" }}>Data/hora da geração: </span>
              {formatarDataHora(dataHoraGeracao)}
            </Typography>
          </Box>
          <Box sx={{ marginY: 5, width: "50%" }}>
            <Divider />
          </Box>
          <Box>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Com base nos dados informados, confirma-se a autenticidade do
              documento.
            </Typography>
            <Typography variant="body1">
              O documento está disponível para conferência e download.
            </Typography>
          </Box>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginTop: 5, marginLeft: 18 }}
            onClick={() =>
              downloadFileFromBase64(
                validatedDocument.arquivo.conteudo,
                validatedDocument.arquivo.nomeOriginal
              )
            }
          >
            Download
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
