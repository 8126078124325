import React, { useState, createContext, useCallback, useMemo, useEffect } from "react";

interface TitleContextData {
  setTitle(value: string): void;
  title: string;
  ambiente: 'dev' | 'hml' | 'esp' | string;
}
export const TitleContext = createContext<TitleContextData>({} as TitleContextData);

export const TitleProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState('');

  const ambiente = useMemo(() => {
    return window.location.origin.split(/:\/\/|\.|\/|:/)[1].split('-')[1] || '';
  }, []);

  const handleSetTitle = useCallback((value: string) => {
    document.title = `${ambiente !== "" ? `[${ambiente.toUpperCase()}] ` : ""}GVdasa${value !== "" ? " - " + value : ""}`.trim();
    setTitle(value);
  }, [ambiente]);

  useEffect(() => {
    handleSetTitle('');
  }, [handleSetTitle]);

  useEffect(() => {
    const favicon = document.getElementById('favicon-id') as HTMLLinkElement;

    switch (ambiente) {
      case 'esp':
        favicon.href = `${window.location.origin}/favicon-esp.ico`;
        break;
      case 'hml':
        favicon.href = `${window.location.origin}/favicon-hml.ico`;
        break;
      case 'dev':
        favicon.href = `${window.location.origin}/favicon-dev.ico`;
        break;

      default:
        favicon.href = `${window.location.origin}/favicon.ico`;
        break;
    }
  }, [ambiente]);

  return (
    <TitleContext.Provider value={{ title, ambiente, setTitle: handleSetTitle }}>
      {children}
    </TitleContext.Provider>
  );
};
