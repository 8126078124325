/**
 * Contém variáveis de ambiente para mais fácil utilização
 */
export const Environment = {
  /**
   * Configura a mensagem que será exibida dentro dos campos de pesquisa
   */
  INPUT_DE_BUSCA: "Pesquisar...",
  /**
   * Configura o limite de registros a serem exibidos nas listagens
   */
  LIMITE_DE_LINHAS: 10,
  /**
   * Configura a mensagem que será exibida quando uma listagem estiver vazia
   */
  LISTAGEM_VAZIA: "Nenhum registro encontrado.",

  /**
   * Indica modo de `prod` no azure.
   */
  IS_PRODUCTION: process.env.REACT_APP_ENV === "prod",
  /**
   * Indica modo de `dev` no azure.
   */
  IS_DEV: process.env.REACT_APP_ENV === "dev",
  /**
   * Indica modo de `development` na máquina local.
   */
  IS_DEVELOPMENT: process.env.REACT_APP_ENV === "development",
  /**
   * Indica modo de `hml` no azure.
   */
  IS_HOMOLOG: process.env.REACT_APP_ENV === "hml",

  /**
   * Informa a url para a API
   */
  URL_API:
    window.location.host.includes("localhost") ||
    window.location.host.includes("-dev")
      ? `https://api-dev.gvdasa.com.br/autenticacao-documentos/api/v1`
      : window.location.host.includes("-hml")
      ? `https://api-hml.gvdasa.com.br/autenticacao-documentos/api/v1`
      : `https://api.gvdasa.com.br/autenticacao-documentos/api/v1/`,

  /**
   * Informada quando está na máquina local, para permitir interação com o gateway.
   */
  URL_ORIGIN_DEBUG: window.location.host.includes("localhost")
    ? "https://area87gvcollege2-dev.professor.gvdasa.com.br"
    : "",


  RECAPTCHA_CLIENT_KEY: "6LeY9vQlAAAAAJPBy4bLE6qDF8kMNySuUEtJm2W8"
};



