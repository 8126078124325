import { AppProvider } from "shared/contexts/AppProvider";
import { AppRoutes } from "routes";

export const App = () => {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
};
