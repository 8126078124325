/* eslint-disable react-hooks/rules-of-hooks */
import { AxiosError } from "axios";

export const errorInterceptor = (error: AxiosError) => {
  const { data } = error.response || { data: undefined };

  if (error.response?.status === 401) {

    const user: string[] = Object.keys(localStorage).filter((i) =>
      i.includes("USER_PROFILE")
    );

    !!user.length && user.map((profile) => localStorage.removeItem(profile));
  }

  if (error.message === "Network Error") {
    return Promise.reject({ ...error, message: "Erro de conexão." });
  }

  if (data) {
    return Promise.reject(error);
  }

  return Promise.reject(error);
};
