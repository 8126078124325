import { format } from 'date-fns';

export const formatarDataHora = (data: Date | null): string => {
  if (data === null) {
    return '';
  }

  const formatoSaida = 'dd/MM/yyyy HH:mm:ss';
  const dataHoraFormatada = format(data, formatoSaida);
  return dataHoraFormatada;
}
