import { useContext, useEffect } from "react";

import { TitleContext } from "../contexts/TitleContext";

export const useTitle = () => {
  return useContext(TitleContext);
};

export const useSetTitle = (title: string): void => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);
};
