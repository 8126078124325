import React from "react";
import { Box } from "@mui/material";

export const LayoutBase: React.FC = ({ children }) => {
  return (
    <Box
      height="100vh"
      display="flex"
      component="main"
      flexDirection="column"
      bgcolor="background.default"
    >
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        sx={{ transition: "all .2s" }}
      >
        <Box maxWidth="xl">{children}</Box>
      </Box>
    </Box>
  );
};
