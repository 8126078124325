import { createTheme } from "@mui/material";

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: "#018781",
      dark: "#005E5A",
      light: "#339F9A",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#01B9AD ",
      dark: "#00A293",
      light: "#B3EAE6",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#F50000",
      light: "#F73333",
      dark: "#AB0000",
    },
    warning: {
      main: "#FFAC33",
      light: "#FF9800",
      dark: "#B26A00",
    },
    success: {
      main: "#33B673",
      light: "#00A451",
      dark: "#007238",
    },
    background: {
      default: "#F8F8F8",
      paper: "#FFFFFF",
    },
    info: {
      main: "#018781",
      light: "#339F9A",
      dark: "#005E5A",
    },
    text: {
      primary: "#414141",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
