import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { Auth, Result } from "../pages";

/*
  Para ALTERAR AS OPÇÕES de menu no drawer edite
  este aquivo: ./../shared/contexts/DrawerContext.tsx
*/

export const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Auth />} />
      <Route path={"/resultado"} element={<Result />} />

      <Route path={"/"} element={<Navigate to="/" />} />
      <Route path={"*"} element={<Navigate to="/" />} />
    </Routes>
  );
};
