import React from "react";

import { BrowserRouter } from "react-router-dom";
import { ThemesProvider } from "./ThemeContext";
import { TitleProvider } from "./TitleContext";
import { AppRoutes } from "../../routes";
import { LayoutBase } from "../layouts";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { DocsProvider } from "./DocsContext";

export const AppProvider: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <TitleProvider>
        <ThemesProvider>
          <DocsProvider>
            <BrowserRouter>
              <LayoutBase>
                <AppRoutes />
              </LayoutBase>
            </BrowserRouter>
          </DocsProvider>
        </ThemesProvider>
      </TitleProvider>
    </LocalizationProvider>
  );
};
