import React, { useCallback, useState } from "react";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactInput from "react-input-mask";

import { DocsService, IDoc } from "shared/services/api/docs/docsService";
import { useDocsContext } from "shared/contexts/DocsContext";
import { ErrorCard } from "./components/ErrorCard";
import { logoGvDasa2 } from "../../shared/assets";
import { Recaptcha } from "./components/Recaptcha";

export const Auth: React.FC = () => {
  const navigate = useNavigate();
  const {
    chave,
    dataHoraGeracao,
    defineChave,
    defineDataHoraGeracao,
    defineValidatedDocument,
  } = useDocsContext();
  const [validationHasErros, setValidationHasErrors] = useState(false);
  const [recaptchaValidated, setRecaptchaValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");

  const canValidDocs = chave && dataHoraGeracao && recaptchaValidated;

  const validDoc = useCallback(() => {
    if (dataHoraGeracao === null) return;
    setValidationHasErrors(false);
    setIsLoading(true);
    DocsService.getValidationDocument(chave, dataHoraGeracao, token)
      .then((doc: IDoc) => {
        defineValidatedDocument(doc);
        if (doc.documentoVerificado) {
          navigate("/resultado");
        } else {
          setValidationHasErrors(true);
        }
      })
      .catch((error: Error) => {
        setValidationHasErrors(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [chave, dataHoraGeracao, defineValidatedDocument, navigate, token]);

  const recaptchaValidation = useCallback(
    (recaptchaToken: string, success: boolean) => {
      setToken(recaptchaToken);
      setRecaptchaValidated(success);
    },
    []
  );

  return (
    <Box
      sx={{
        height: "100vh",
        background: "linear-gradient(180deg, #00A49D 0%, #005E5A 100%)",
      }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box
        maxWidth="xs"
        width={700}
        padding={8}
        sx={{ background: "white" }}
        borderRadius={5}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Box sx={{ paddingY: 2 }}>
          <img alt="inadimplencia" src={logoGvDasa2.default} />
        </Box>
        <Box sx={{ paddingY: 2 }}>
          <Typography variant="h6">Autenticação de documentos</Typography>
        </Box>
        <Box width={700} paddingX={8} paddingY={2}>
          <ReactInput
            mask={"****-****-****-****"}
            value={chave}
            onChange={(e) => defineChave(e.target.value)}
          >
            <TextField
              required
              fullWidth
              label="Chave de verificação"
              variant="outlined"
            />
          </ReactInput>
        </Box>
        <Box width={700} paddingX={8} paddingY={2}>
          <DateTimePicker
            label="Data e hora geração"
            value={dataHoraGeracao}
            format="dd/MM/yyyy - HH:mm:ss"
            onChange={(newValue) => defineDataHoraGeracao(newValue)}
            sx={{ width: "100%" }}
          />
        </Box>

        <Box>
          <Recaptcha recaptchaValidation={recaptchaValidation} />
        </Box>

        {validationHasErros && !isLoading && <ErrorCard />}
        <Box width={700} paddingX={8} paddingY={2}>
          {!isLoading ? (
            <Button
              fullWidth
              disabled={!canValidDocs}
              color="primary"
              variant="contained"
              onClick={validDoc}
            >
              Validar
            </Button>
          ) : (
            <Button
              fullWidth
              disabled
              variant="contained"
              startIcon={<CircularProgress color="primary" size={18} />}
            >
              Validando documento
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
