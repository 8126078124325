import { Api } from "../axios-config/AxiosConfig";

export interface IDoc {
  documentoVerificado: boolean,
  chaveVerificacao: string,
  dataHoraGeracao: Date,
  dataHoraValidade: Date,
  idArquivo: string,
  arquivo: IArquivo
}

export interface IArquivo{
  nomeOriginal: string,
  conteudo: string
}


const getValidationDocument = async (chave:string, dataHoraGeracao: Date, token:string): Promise<IDoc> => {
  const dataGeracao = `${dataHoraGeracao.getFullYear()}-${(dataHoraGeracao.getMonth() + 1).toString().padStart(2, '0')}-${dataHoraGeracao.getDate().toString().padStart(2, '0')}`;
  const horaGeracao = `${dataHoraGeracao.getHours().toString().padStart(2, '0')}:${dataHoraGeracao.getMinutes().toString().padStart(2, '0')}:${dataHoraGeracao.getSeconds().toString().padStart(2, '0')}`;
  try {
    const {data} = await Api().get<IDoc>(`/DocumentoAutenticado?chaveVerificacao=${chave}&dataGeracao=${dataGeracao}&horaGeracao=${horaGeracao}&recaptchaInput=${token}`);
    return data;
  } catch (error: any) {
    throw new Error(error.response);
  }
};


export const DocsService = {
  getValidationDocument,
};
