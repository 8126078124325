import React from "react";
import { Environment } from "shared/environment";

import ReCAPTCHA from "react-google-recaptcha";

interface IRecaptchaProps {
  recaptchaValidation: (token: string, success: boolean) => void;
}

export const Recaptcha: React.FC<IRecaptchaProps> = ({
  recaptchaValidation,
}) => {
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const onSubmitWithReCAPTCHA = () => {
    const token = recaptchaRef.current?.getValue();

    const recaptchaToken = token || "";
    const success = token ? true : false;

    recaptchaValidation(recaptchaToken, success);
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={Environment.RECAPTCHA_CLIENT_KEY}
      onChange={onSubmitWithReCAPTCHA}
    />
  );
};
