import React, { useMemo, useState, useContext, createContext } from "react";
import { ThemeProvider, Theme, CssBaseline } from "@mui/material";

import { LightTheme, LightEspelho, LightHomologacao } from "./../themes";
import { useTitle } from "../hooks";

interface IThemeContextData {
  toggleTheme(): void;
  theme: Theme;
}

export const useThemesContext = () => useContext(ThemeContext);

const ThemeContext = createContext<IThemeContextData>({} as IThemeContextData);

export const ThemesProvider: React.FC = ({ children }) => {
  const { ambiente } = useTitle();

  const temaPorAmbiente = useMemo(() => {
    switch (ambiente) {
      case "dev":
        return LightTheme;
      case "esp":
        return LightEspelho;
      case "hml":
        return LightHomologacao;

      default:
        return LightTheme;
    }
  }, [ambiente]);

  const [themeData] = useState<IThemeContextData>({
    toggleTheme: () => {},
    theme: temaPorAmbiente,
  });

  return (
    <ThemeContext.Provider value={themeData}>
      <ThemeProvider theme={themeData.theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
