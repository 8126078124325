import React, { useState, useContext, createContext } from "react";
import { IDoc } from "shared/services/api/docs/docsService";

interface IDocsContextData {
  chave: string;
  dataHoraGeracao: Date | null;
  defineChave: (value: string) => void;
  defineDataHoraGeracao: (value: Date | null) => void;
  validatedDocument: IDoc;
  defineValidatedDocument: (value: IDoc) => void;
}

export const useDocsContext = () => useContext(DocsContext);

const DocsContext = createContext<IDocsContextData>({} as IDocsContextData);

export const DocsProvider: React.FC = ({ children }) => {
  const [chave, setChave] = useState<string>("");
  const [dataHoraGeracao, setDataHoraGeracao] = useState<Date | null>(null);
  const [validatedDocument, setValidatedDocument] = useState<IDoc>({} as IDoc);

  const defineChave = (value: string) => {
    setChave(value);
  };

  const defineDataHoraGeracao = (value: Date | null) => {
    setDataHoraGeracao(value);
  };

  const defineValidatedDocument = (value: IDoc) => {
    setValidatedDocument(value);
  };

  return (
    <DocsContext.Provider
      value={{
        chave,
        dataHoraGeracao,
        defineChave,
        defineDataHoraGeracao,
        validatedDocument,
        defineValidatedDocument,
      }}
    >
      {children}
    </DocsContext.Provider>
  );
};
