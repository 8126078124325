import Axios, { AxiosInstance } from "axios";

import { errorInterceptor, responseInterceptor } from "./interceptors";
import { Environment } from "../../../environment";

export const Api = (): AxiosInstance => {
  const config = Axios.create({
    baseURL: Environment.URL_API,
    withCredentials: true,
    headers: window.location.host.includes("localhost") && {
      origin_debug: `${Environment.URL_ORIGIN_DEBUG}`,
    },
  });

  config.interceptors.response.use(
    (response) => responseInterceptor(response),
    (error) => errorInterceptor(error)
  );

  return config;
};
